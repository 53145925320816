<template>
    <base-overlay :class="'is-dark'" @close="closeOverlay"></base-overlay>
</template>

<script>
import BaseOverlay from "../components/baseOverlay";
import overlayMixin from "../mixins/overlayMixin";

export default {
    name: "PageOverlay",
    components: {BaseOverlay},
    mixins: [overlayMixin],
}
</script>

<style scoped>
</style>
