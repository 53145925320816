<template>
    <!--    <base-overlay @close="closeOverlay">-->
    <!--        <div class="content-wrapper" tabindex="0">-->
        <div class="page">
            <div v-if="page" class="block-page">
                <div class="block-page__inner" :class="{'block-page__inner--media':hasMedia}">
                    <div class="block-page__col">
                        <h1 class="block-page__title" :class="{'block-page__title--large':hasMedia}"
                            v-html="page.meta.post_title || page.title.rendered"/>
                        <h2 v-if="page.meta.post_subtitle" class="block-page__subtitle"
                            v-html="page.meta.post_subtitle"/>
                        <router-link v-if="link" :to="link.url"
                                     class="button button-bordered button-play">
                            {{ link.title }}
                        </router-link>
                        <div v-if="page?.content" v-html="page.content.rendered" class="block-page__content"></div>
                    </div>
                    <div v-if="hasMedia" class="block-page__col">
                        <div class="block-page__media">
                            <transition name="fade">
                                <figure v-if="thumbnail" class="block-page__media-image">
                                    <img :src="thumbnail_size('medium_large')"/>
                                </figure>
                            </transition>
                            <transition name="slide-in">
                                <div v-if="page.acf.media_layout.link" :set="link = page.acf.media_layout.link"
                                     class="block-page__media-nav">
                                    <router-link :to="link.url">
                                        <span class="block-page__play"></span>
                                        {{ link.title }}
                                    </router-link>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div v-if="hasForm" class="block-page__col" :set="layout = page.acf.form_layout ">
                        <h4 v-if="layout.title" class="block-page__subhead">{{ layout.title }}</h4>
                        <keep-alive>
                            <contact-form v-if="form" :form="form"></contact-form>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
        <!--        </div>-->
        <!--    </base-overlay>-->
</template>

<script>
import store from "../store";
import actions from "../modules/fetchAxios";
import BaseOverlay from "../components/baseOverlay";
import overlayMixin from "../mixins/overlayMixin";
import formMixin from "../mixins/formMixin";
import ContactForm from "../components/contact-form";

export default {
    name: "PagePromo",
    components: {ContactForm},
    mixins: [overlayMixin, formMixin],
    data() {
        return {
            thumbnail: null,
            form_id: null
        }
    },

    computed: {
        page() {
            return store.page
        },
        hasMedia() {
            return store.page && store.page.acf.media_layout && store.page.acf.media_layout.thumbnail
        },
        hasForm() {
            return store.page && store.page.acf.form_layout && store.page.acf.form_layout.form
        },
        link() {
            return store.page && store.page.acf.form_layout && store.page.acf.form_layout.link
        }
    },

    watch: {
        page: {
            handler: function (val) {
                if (val) {
                    if (this.page.acf && this.page.acf.media_layout && this.page.acf.media_layout.thumbnail) {
                        actions.getMedia(this.page.acf.media_layout.thumbnail)
                            .then(res => {
                                this.thumbnail = res
                            })
                    }

                    if (this.page.acf && this.page.acf.form_layout && this.page.acf.form_layout.form) {
                        this.form_id = this.page.acf.form_layout.form
                    }
                }
            },
            immediate: true
        },
    },

    methods: {
        thumbnail_size($size, $thumbnail = this.thumbnail) {
            if ($thumbnail) {
                let media = $thumbnail.media_details.sizes[$size] || $thumbnail.media_details.sizes['full']

                return media.source_url
            }

            return '';
        }
    }

}
</script>

<style scoped>
</style>