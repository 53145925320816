import store from "../store";
import actions from "../modules/fetchAxios";

export default {

    watch: {
        form_id: {
            handler: function (val) {
                if (val) {
                    this.loadForm()
                }
            },
            immediate: true
        }
    },

    computed: {
        form() {
            return store.form
        },

        form_field_errMessage() {
            return store.form_field_errMessage
        }
    },
    methods: {
        async loadForm() {
            store.form = await actions.getForm(this.form_id)
        }
    }

}