import actions from "../modules/fetchAxios";
import store from "../store";

export default {
    methods: {
        async loadVideo() {
            store.video = await actions.getVideo(this.video_id)
        },

        async loadTrailers() {
            if (store.fetching && store.fetching.trailers)
                return

            store.fetching.trailers = 1
            await actions.fetchEntries(store.trailers, 'trailer')
                .finally(() => {
                    store.fetching.trailers = 0
                })
        },

        async loadPosts() {
            if (store.fetching && store.fetching.posts)
                return

            store.fetching.posts = 1
            await actions.fetchEntries(store.posts, 'posts')
                .finally(() => store.fetching.posts = 0);
        },

        async loadCategories() {
            if (store.fetching && store.fetching.posts_categories)
                return

            store.fetching.posts_categories = 1
            await actions.fetchEntries(store.posts_categories, 'categories')
                .finally(() => store.fetching.posts_categories = 0);
        },

        async loadPrivacyPage() {
            store.pp_page_content = await actions.fetchPage(this.pp_page_id)
        },

        async loadWidget(id) {
            store.widget = await actions.fetchWidget(id)
            store.widgets.push(store.widget)
        },
    }
}