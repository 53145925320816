<template>
    <base-overlay v-if="menu" @close="closeOverlay">
        <div class="content-wrapper" tabindex="0">

            <transition name="fade" mode="out-in">
                <keep-alive exclude="carousel">
                    <router-view :key="$route.params.menuSlug" class="has-logo"></router-view>
                </keep-alive>
            </transition>

        </div>
    </base-overlay>
</template>

<script>
import store from "../store";
import requestMixin from "../mixins/requestMixin";
import BaseOverlay from "../components/baseOverlay";
import overlayMixin from "../mixins/overlayMixin";

export default {
    name: "Overlay",
    components: {BaseOverlay},
    mixins: [overlayMixin, requestMixin],
    props: {menuSlug: String, transitionEntered: Boolean},
    computed: {
        menu() {
            return store.menu
        },
        // video_id() {
        //     return store.video_id
        // },
        pp_page_id() {
            return store.pp_page_id
        }
    },
    mounted() {
        // if (!store.video || !Object.keys(store.video).length) {
        //     if (this.video_id) {
        //         this.loadVideo()
        //     }
        // }
        //
        // if (!store.trailers || !store.trailers.length) {
        //     this.loadTrailers()
        // }

        if (!store.pp_page_content || !Object.keys(store.pp_page_content).length) {
            if (this.pp_page_id) {
                this.loadPrivacyPage()
            }
        }

        if (!store.posts || !store.posts.length) {
            this.loadPosts()
        }

        if (!store.posts_categories || !store.posts_categories.length) {
            this.loadCategories()
        }
    },
    watch: {
        // video_id(val) {
        //     if (val) {
        //         this.loadVideo()
        //     }
        // },

        pp_page_id(val) {
            if (val) {
                this.loadPrivacyPage()
            }
        }
    },
    methods: {}
}
</script>

<style scoped>
</style>
